import moment from "moment/moment";

export function formatMonth (month){

    let romanMonth = "";
    // eslint-disable-next-line
    switch(month){
        case 1:
            romanMonth = "I"
            break;
        case 2:
            romanMonth = "II"
            break;
        case 3:
            romanMonth = "III"
            break;
        case 4:
            romanMonth = "IV"
            break;
        case 5:
            romanMonth = "V"
            break;
        case 6:
            romanMonth = "VI"
            break;
        case 7:
            romanMonth = "VII"
            break;
        case 8:
            romanMonth = "VIII"
            break;
        case 9:
            romanMonth = "IX"
            break;
        case 10:
            romanMonth = "X"
            break;
        case 11:
            romanMonth = "XI"
            break;
        case 12:
            romanMonth = "XII"
            break;

    }
    return romanMonth;
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function formatDate (date){
    if (date === null || typeof date == 'undefined' )
      return null;

    let newDate = date.split("T")[0];
    let newDateFormated = newDate.split("-");
    let toReturn = newDateFormated[2] + " / " +formatMonth(parseInt(newDateFormated[1]));

    return toReturn;

}

export function formatDate2(datetime) {
    if (!datetime) return '-';

    const date = new Date(datetime);
    const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate();
    const month = date.getUTCMonth() < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes <= 9) {
        minutes = minutes + "0";
    }

    return day + '-' + month + '-' + year + " | " + hours + ':' + minutes;
}

export function isInvatator(clasa) {

    if(!clasa) return ''

    let newClass = clasa.toString()

        .replace('-',' ')
        .replace('Clasa a', '')
        .replace('Clasa', '')
        .replace('Clasaa', '')
        .replace('IX', '9')
        .replace('XII', '12')
        .replace('XI', '11')
        .replace('X', '10')
        .replace('VIII', '8')
        .replace('VII', '7')
        .replace('VI', '6')
        .replace('IV', '4')
        .replace('V', '5')
        .replace('III', '3')
        .replace('II', '2')
        .replace('I', '1')
        .replace(' ', '')
        .replace(/\s/g, "")

    return parseInt(newClass) <= 4 || isNaN(parseInt(newClass))
}

export function numberToFixed(num, fixed = 2) {

    // eslint-disable-next-line
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');

    if(num.toString().match(re)) {
        return parseFloat(num.toString().match(re)[0]);
    } else {
        return 0
    }
}

export function convertMinutesToReadable(num){
    let h = Math.floor(num/60);
    let m = num%60;

    if(h === 0) {
        return(m +' min.').toString();
    }

    return(h + 'ore '+m+'min.').toString();
}
export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function getTimeSince(date) {
    let dateFormated = moment(date).format('DD/MM')
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return moment(date).format('DD/MM/YY');
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return dateFormated;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return dateFormated + ' (acum ' + Math.floor(interval) + " zile)";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return 'acum ' + Math.floor(interval) + " ore";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return 'acum ' + Math.floor(interval) + " minute";
    }
    return 'acum ' + Math.floor(seconds) + " secunde";
}
export const loadingMessages = [
    "Stai puțin! Miorița noastră digitală își strunește oile de pixeli pentru a-ți procesa datele. 🐑",
    "Răbdare! Hora IT-ului e în plină desfășurare pentru a-ți genera orarul. Încă un pas și e gata!",
    "Așteaptă! Dracula se ocupă personal de securitatea datelor tale. Nimic nu scapă din castelul digital! 🦇",
    "Un moment! Căluții de la Mărțișor lucrează în ritm de sârbă pentru a-ți aduce cele mai bune rezultate. 🐴",
    "Nu pleca! Ciobănașii digitali își strâng oile în cloud și se pregătesc să coboare cu ele în platforma ta.",
    "Răbdare! Urșii noștri carpatini sunt pe cale să aducă un plus de putere serverelor. 🐻",
    "Așteaptă un pic! Palincă digitală e pe foc, iar codul se distilează perfect pentru tine. 🍶",
    "Stai pe aproape! Echipa noastră de la munte scanează Carpații digitali pentru a-ți oferi viziunea clară a datelor. 🏞️",
    "O secundă! La noi în satul virtual, fântâna cu date e adâncă. Dar găleata noastră e rapidă!",
    "Așteaptă! În vatra digitală a României, căruțașii IT își pun caii la treabă pentru a-ți transporta informația. 🐎",
    "Stai liniștit! Cucu' nostru bate ora exactă în cod, așa că datele tale sunt pe cale să fie gata. 🕰️",
    "Răbdare! În atelierul nostru digital, olarii lucrează la roată pentru a modela platforma perfectă. 🏺",
    "Așteaptă! Doina codului răsună în valea serverelor. Încă un refren și suntem gata!",
    "Un moment! Sarmalele digitale sunt pe foc. Când miroase a cod gata, te anunțăm! 🍲",
    "Nu pleca! Mămăliga codului se coace. Când e aurie și pufoasă, te servim cu datele! 🌽",
    "Răbdare! Bătrânii satului virtual țin sfat. Rezultatele vor veni în ritm de nai și cobză.",
    "Așteaptă! În piața digitală, zarzavagii noștri aleg cele mai proaspete date pentru tine. 🥕",
    "Stai pe aproape! Pe câmpul digital, secerișul de date e în toi. Grâul codului va fi în hambar în curând! 🌾",
    "O secundă! Pe Dunărea virtuală, lăutarii cântă balada datelor tale. Încă o strofă și e gata!",
    "Așteaptă! La hanul digital, ospătarul aduce comanda. Tu stai comod și savurează o cană de informație proaspătă! 🍺",
]
