export const tooltipHeader = {
    fontSize: '0.9rem'
}

export const styleTooltip = {
    display: 'flex',
    paddingTop: '5px'
}
export const styleInnerParagraph = {
    flex: 7,
}
export const styleInnerIconWrong = {
    flex: 1,
    paddingTop: '2px',
    color: '#05B70A'
}
export const styleInnerIconRight = {
    flex: 1,
    paddingTop: '2px',
    color: '#D42804'
}