import React from "react";
import {ReactComponent as Logo} from "../../Assets/icons/Logo-Tinker.svg";
import {toast} from 'react-toastify'
import Axios from "axios";

class ChangeEmail extends React.Component {

    state = {
        parolaActuala: '',
        parolaNoua: '',
        confirmParola: '',
        containsNumbers: false,
        isUppercase: false,
        containsSymbols: false,
        repetaParola: '',
        termeni: false,
        pressed: false,
        email: "",
        valid: true,
        validConfirm: true,
        showPass: false,
    }

    componentDidMount = async () => {
        try {
            await Axios.post(process.env.REACT_APP_STRAPI_URL + '/user-info/changeEmail', {token: this.props.match.params.id, email: this.props.match.params.email})

            toast.success("Adresa de email s-a modificata cu succes")
            this.props.history.push('/')
            // this.props.history.push('/2fa-config')
        } catch (e) {
            toast.error("A apărut o eroare!")
        }
    };

    render() {
        return (
            <div className="update-psw-page">
                <div className="update-psw-logo-container">
                    <Logo className="update-psw-logo"/>
                </div>

                <div className="update-psw-content-container">


                </div>
            </div>
        );

    }


}

export default ChangeEmail;
