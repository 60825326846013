import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'rsuite/dist/styles/rsuite-default.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ReactDOM.render(
  //<React.StrictMode>
    <App />,
 // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// UNIMPLEMENTED STRAPI ENDPOINTS
/* 
  /motiveazaAllAbsente
  /updateNotaPurtare
  /parinteGetFullList
  /membership/returnNotifications
  /getScheduleParinteWidget
  /getScheduleElevWidget
  /getScheduleParinte
  /schedules/getScheduleProfesor
  /parinteGetFullList
  /membership/returnNotifications
  /getConturiAsociate
  /getProfileInfo
  /createParinte
  /teacher-profiles
  /generateQR
  /registerToken
*/
